import React from 'react';



const icons = {


needelogo:"M4.94209927,7.9669405 C4.94209927,7.69079812 5.1659569,7.4669405 5.44209927,7.4669405 L6.73819892,7.4669405 L3.3368012,4.06554277 L3.3368012,12.0645365 L4.94209927,13.6698346 L4.94209927,7.9669405 Z M10.1880074,7.4669405 L10.1880074,1.05693963 C10.1880074,0.611487205 10.7265783,0.38840381 11.0415608,0.70338624 L13.6196461,3.28147161 C13.7134143,3.3752398 13.7660927,3.50241676 13.7660927,3.63502501 L13.7660927,13.2877275 C13.7660927,13.73318 13.2275218,13.9562634 12.9125394,13.6412809 L6.73819892,7.4669405 L10.1880074,7.4669405 Z M5.94209927,8.4669405 L5.94209927,14.8769414 C5.94209927,15.3223938 5.40352831,15.5454772 5.08854588,15.2304948 L2.48324781,12.6251967 C2.38947962,12.5314285 2.3368012,12.4042515 2.3368012,12.2716433 L2.3368012,2.85843599 C2.3368012,2.41298356 2.87537216,2.18990017 3.19035459,2.5048826 L9.15241249,8.4669405 L5.94209927,8.4669405 Z M9.15241249,8.4669405 L12.7660927,12.0806208 L12.7660927,3.84213179 L11.1880074,2.26404641 L11.1880074,7.9669405 C11.1880074,8.24308287 10.9641497,8.4669405 10.6880074,8.4669405 L9.15241249,8.4669405 Z"

}

const LogoComponent = (props) => (
  <svg width="40px" height="40px" viewBox="0 0 16 16" className="needelogo" >
    <path d={icons[props.icon]}></path>

  </svg>
);


export default LogoComponent;
